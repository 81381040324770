<template>
	<div class="s-mb-30">
		<edcLoading v-if="loading"/>
		<div v-else-if="data.length == 0" class="s-fullWidth">
            <div class="s-bg-secondary s-ptb-3 s-radius-10 s-fullWidth s-text-center">
                <p class="s-weight-600 s-text-white">{{$t('contentList.contentNotFound')}}</p>
            </div>
        </div>
		<div v-else class="s-center-flex s-wrap">
			<div class="s-column-3" v-for="(i, index) in data" :key="`education-${index}`">
				<router-link :to="$translate({name:'Education Detail', params: {slug: i.slug}})" class="s-text-none" >
					<div class="s-card-content s-pb-5 s-m-16">
						<div class="s-card-image">
							<img class="s-fullHeight" :src="i.image">
						</div>
						<div class="s-m-16">
							<div class="s-line-clamp">
								<h5>{{i.title}}</h5>
							</div>
							<p class="s-weight-600 s-text-primary s-text-italic">{{$t('eduList.eduAuthor')}}: {{i.created_by}}</p>
						</div>
					</div>
				</router-link>
			</div>
		</div>
		
	</div>
</template>
<script>
import edcLoading from '@/components/public/edcCardLoading'
export default {
	props: {
		data:{
			required: true
		},
		loading: {
			default: false
		}
	},
	components: {
		edcLoading
	}
}
</script>
